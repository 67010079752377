/* eslint-disable @next/next/no-img-element */
'use client';
import { HiBadgeCheck } from 'react-icons/hi';
import { Marquee } from './ui/Marquee';

const Testimonials = () => {
  return (
    <div className="lg:pt-36 pb-20 pt-20">
      <div className="mb-6 px-8 max-w-6xl mx-auto">
        <h3 className="text-[var(--foreground)] text-4xl font-semibold text-left">
          What Yodlers will say{' '}
        </h3>
      </div>
      <div className="relative">
        <Marquee pauseOnHover className="[--duration:50s]">
          {testimonials.top.map((t) => (
            <div
              key={t.id}
              className="shrink-0 w-[320px] bg-[var(--card)] rounded-2xl overflow-hidden border border-[var(--border)] px-8 py-6"
            >
              <div className="flex gap-3">
                <img
                  src={t.img}
                  className="w-12 h-12 rounded-full object-cover"
                  alt={t.name}
                />
                <div>
                  <span className="font-semibold text-[var(--foreground)] inline-flex gap-2">
                    {t.name}{' '}
                    <HiBadgeCheck
                      size={24}
                      className="fill-[#1DA1F2] text-[var(--card)]"
                    />
                  </span>
                  <span className="block text-sm text-[var(--foreground)]">
                    {t.title}
                  </span>
                </div>
              </div>
              <div className="pt-8">
                <p className="text-[var(--foreground)] text-sm">{t.info}</p>
              </div>
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

const testimonials = {
  top: [
    {
      id: 1,
      img: '/assets/lander/avatars/avatar01.png',
      name: 'Bailey Simrell',
      title: '@bailysimrell',
      info: 'Yodl looks amazing! 🔥',
    },
    {
      id: 2,
      img: '/assets/lander/avatars/avatar02.png',
      name: 'Christian Alarres',
      title: '@chrisals',
      info: 'So easy to send payments',
    },
    {
      id: 3,
      img: '/assets/lander/avatars/avatar03.png',
      name: 'Frankie Lu',
      title: '@franklu',
      info: "Yodl's platform is exceptional! Their user-friendly interface and smooth payments have transformed my business. Highly recommended!",
    },
    {
      id: 4,
      img: '/assets/lander/avatars/avatar04.png',
      name: 'Steven Tay',
      title: '@taytay',
      info: 'Awesome work. I love it!',
    },
    {
      id: 5,
      img: 'https://images.unsplash.com/photo-1629425733761-caae3b5f2e50?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
      name: 'Kyle Ranch',
      title: '@ranchstyle',
      info: 'Ingenious approach to payments 🧠',
    },
  ],
};

export default Testimonials;
