/* eslint-disable @next/next/no-html-link-for-pages */
'use client';

import { Button } from './ui/Button';
import { Spotlight } from './ui/Spotlight';

export default function Hero() {
  return (
    <div>
      <div className="relative isolate px-6 pt-14 lg:px-8 overflow-hidden">
        <Spotlight className="-top-10 left-0 md:left-60 md:top-20" />
        <div className="mx-auto max-w-2xl py-32">
          <div className="hidden sm:mb-4 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-md text-[var(--purple)] font-medium">
              Use your wallet. We handle the rest.
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-balance text-5xl font-semibold tracking-tight text-[var(--foreground)] sm:text-7xl">
              Payment rails for stablecoins.
            </h1>
            <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
              Yodl lets you pay or receive from anyone, anywhere—
              <br />
              <span className="text-[var(--purple)] font-bold">
                any token, any chain, any currency.
              </span>
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a href="/go" className="text-sm/6 font-semibold text-gray-900">
                <Button size="lg">Get Started</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
